import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex justify-between -mt-4 border-b" }
const _hoisted_4 = { class: "flex items-center text-lg font-bold text-gray-900" }
const _hoisted_5 = { class: "flex py-4" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "font-medium text-gray-900" }
const _hoisted_8 = {
  key: 0,
  class: "text-sm font-medium text-gray-500"
}
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "font-medium text-gray-900" }
const _hoisted_11 = {
  key: 0,
  class: "text-sm font-medium text-gray-500"
}
const _hoisted_12 = { class: "inline-flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('platform.form.all')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BasePopover, {
            position: "left",
            "data-dusk": "new-form"
          }, {
            button: _withCtx(() => [
              _createVNode(_component_BaseButton, {
                class: "ml-4",
                "left-icon": "add",
                type: "button",
                "data-dusk": "new-form-button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.form.new')), 1)
                ]),
                _: 1
              })
            ]),
            menu: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.forms, (form) => {
                return (_openBlock(), _createBlock(_component_PopoverButton, {
                  key: form.id,
                  "data-dusk": "form-selection",
                  onClick: ($event: any) => (_ctx.newFormSubmission(form.id))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(form.name), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode(_component_DataTable, {
        loading: _ctx.loadingFormSubmissions,
        columns: [
          {
            name: 'patient_visited_at',
            label: _ctx.$t('platform.common.patient-clinic-visit'),
            sortable: true
          },
          {
            name: 'updated_at',
            label: _ctx.$t('platform.common.last-activity'),
            sortable: true
          },
          { name: 'actions', label: '', align: 'right' }
        ],
        rows: _ctx.formSubmissions,
        sort: _ctx.sort,
        class: "w-full mt-6",
        onSort: _ctx.updateSort
      }, {
        "cell.patient_visited_at": _withCtx(({ row, value }) => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(row.form.name), 1),
            value
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$d(new Date(value), 'shortMonth')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        "cell.updated_at": _withCtx(({ row, value }) => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(row.user.given_name) + " " + _toDisplayString(row.user.family_name), 1),
            value
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$d(new Date(value), 'shortMonth')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        "cell.actions": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_ButtonLink, {
              to: `forms/${row.id}`,
              color: row.completed_at ? 'ghost' : 'success',
              "right-icon": "chevron-right"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.completed_at ? _ctx.$t('platform.common.view') : _ctx.$t('platform.common.continue')), 1)
              ]),
              _: 2
            }, 1032, ["to", "color"])
          ])
        ]),
        _: 1
      }, 8, ["loading", "columns", "rows", "sort", "onSort"])
    ])
  ]))
}