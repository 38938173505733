import { GenericService } from '@/services/api/generic.service';
import { FormSubmission } from '@/models';

export class ParticipantFormSubmissionService extends GenericService<FormSubmission> {
  endpoint = '';

  constructor(id: string) {
    super();
    this.endpoint = `v1/anonymous-participants/${id}/form-submissions`;
  }
}
