
import { Vue, Options } from 'vue-class-component';
import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { BaseButton, ButtonLink, DataTable, BasePopover, PopoverButton, SpecialityButton } from '@/lib/components';
import { FormService, PatientFormSubmissionService } from '@/services/api';
import { Form, FormSubmission } from '@/models';
import { ParticipantFormSubmissionService } from '@/custom/research/services/participant-form-submission.service';
import { FORM_TYPE_RESEARCH } from '@/constants/patients';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(relativeTime);
dayjs.extend(utc);

@Options({
  props: {
    patientId: {
      type: String,
      default: null
    },
    participantId: {
      type: String,
      default: null
    }
  },
  components: {
    BaseButton,
    ButtonLink,
    DataTable,
    BasePopover,
    PopoverButton,
    SpecialityButton
  }
})
export default class PatientFormSubmissionsPage extends Vue {
  patientId!: string | null;
  participantId!: string | null;
  loadingForms = true;
  loadingFormSubmissions = true;

  forms: Form[] = [];
  formSubmissions: FormSubmission[] = [];
  search = '';
  service = this.patientId
    ? new PatientFormSubmissionService(this.patientId)
    : this.participantId
      ? new ParticipantFormSubmissionService(this.participantId)
      : null;

  formService = new FormService();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || '-patient_visited_at';
  }

  async created() {
    this.search = String(this.$route.query.search || '');
    try {
      this.loadingForms = true;
      await this.getForms();
      this.loadingFormSubmissions = true;
      await this.getFormSubmissions();
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    } finally {
      this.loadingFormSubmissions = false;
      this.loadingForms = false;
    }

    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        try {
          this.loadingFormSubmissions = true;
          await this.getFormSubmissions();
        } catch (e) {
          this.notificationStore.addErrorNotification({
            title: this.$t('platform.error.fetch-data')
          });
        } finally {
          this.loadingFormSubmissions = false;
        }
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });
  }

  async getForms() {
    const config: AxiosRequestConfig = {
      params: {
        'fields[forms]': 'id,name',
        ...(this.participantId ? { 'filter[type]': FORM_TYPE_RESEARCH } : {})
      }
    };
    this.forms = (await this.formService.index(config)).data;
  }

  async getFormSubmissions() {
    const params = {
      page: this.page,
      sort: this.sort,
      include: 'form,user,patient_visited_at',
      'fields[users]': 'id,given_name,family_name',
      'fields[forms]': 'id,name'
    };
    if (this.service) {
      this.formSubmissions = (await this.service.index({ params })).data;
    } else {
      console.error('patientId or participantId should be provided');
    }
  }

  async newFormSubmission(formId: string) {
    if (this.service) {
      const response = await this.service.create({
        form_id: formId,
        organisational_unit_id: this.sessionStore.currentOrganisationId
      });
      this.$router.push(`forms/${response.data.id}`);
    }
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }
}
